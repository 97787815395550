<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>
<v-container fluid v-if='enable'>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Filtres
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-md-2"><v-select
                  :items="stores"
                  item-value=id
                  item-text=name
                  placeholder="Magasin"
                  v-model=store_id
                ></v-select></div>
                <div class="col-md-2"><v-select
                  :items="categories"
                  item-value=id
                  item-text=name
                  placeholder="Catégorie"
                  v-model=category_id
                ></v-select></div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=filter
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-check-bold</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "reset_modal_button"
            v-on:click=reset
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="red"
            text
            class = "export_modal_button"
            v-on:click=exportPDF 
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
<v-card
  elevation="2" style="margin-top:20px"
>
<div class="row">
  <div class='col-md-12' style="padding-top:0px">
    <div class='table-responsive' style="max-height:500px;overflow-y:scroll">
        <table class="table table-striped table-hover tableFixHead" id="content" style="border-top:none">
          <thead>
            <tr v-if="filter_rate_id == 3">
              <th colspan="2" v-if="filter_rate_id == 3" style="border-right:1px solid #c0c0c0">PRODUIT</th>

              <th colspan="4" v-if="filter_rate_id == 3" style="border-right:1px solid #c0c0c0">HTG</th>
              <th colspan="4" v-if="filter_rate_id == 3">USD</th>
            </tr>
            <tr>
              <th style="border-right:1px solid #c0c0c0;text-align:left">PRODUIT</th>
              <th style="border-right:1px solid #c0c0c0">QTE</th>

              <th  v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">COUT (HTG)</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">VENTES (HTG)</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">PROFIT (HTG)</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">MARGE (%)</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">COUT (USD)</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">VENTES (USD)</th>
              <th v-if="filter_rate_id == 2"  style="border-right:1px solid #c0c0c0">PROFIT (USD)</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">MARGE (%)</th>

            </tr>
            <tr style="color:black;background:#dee2e6">
              <th style='text-align:left;border-right:1px solid #c0c0c0'>TOTAL</th>
              <th style="border-right:1px solid #c0c0c0">{{ totalQuantity() }}</th>

              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ totalCost(1) | formatNumber }}</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ total(1) | formatNumber }}</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ profit(1) | formatNumber }}</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ margin(1) | formatNumber }}%</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ totalCost(2) | formatNumber }}</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ total(2) | formatNumber }}</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ profit(2) | formatNumber }}</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ margin(2) | formatNumber }}%</th>
            </tr>
          </thead>
          <tbody style="margin-top:40px">
            
            <tr  v-for="product in rows" :key="product.product_id">
              <td style="border-right:1px solid #c0c0c0" class="text-left"><strong>{{ product.category_name }} </strong> - {{ product.product_name }}</td>
              <td style="border-right:1px solid #c0c0c0">{{ product.quantity }}</td>

              <td v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ product.turnover.HTG.cost | formatNumber }}</td>
              <td v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ product.turnover.HTG.total | formatNumber }}</td>
              <td v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ (product.turnover.HTG.total - product.turnover.HTG.cost) | formatNumber }}</td>
              <td v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0">{{ (product.turnover.HTG.total - product.turnover.HTG.cost)/product.turnover.HTG.total*100 | formatNumber }}%</td>

              <td v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ product.turnover.USD.cost | formatNumber }}</td>
              <td v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ product.turnover.USD.total | formatNumber }}</td>
              <td v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ (product.turnover.USD.total - product.turnover.USD.cost) | formatNumber }}</td>
              <td v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0">{{ (product.turnover.USD.total - product.turnover.USD.cost)/product.turnover.USD.total*100 | formatNumber }}%</td>

            </tr>
          </tbody>
        </table>
    </div>
  </div>
</div>
</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import moment from 'moment'
import { jsPDF } from "jspdf"
import "jspdf-autotable"

export default {
  name: 'ProductsReportView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/reports/products?from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          console.log(response.data.result)
          self.rows = response.data.result
          self.enable = true          
        }).catch(function(error){
          console.log(error)
        })
    },
    reset: function(){
      this.store_id = ''
      this.category_id = ''
      this.from = moment(new Date()).format('YYYY-MM-DD')
      this.to = moment(new Date()).format('YYYY-MM-DD')

      this.filter()
    },
    totalCost: function(rate_id){
      var total = 0;
      var self = this
      Object.keys(this.rows).forEach(function(key){
        if(rate_id == 1){
          total = total + parseFloat(self.rows[key].turnover.HTG.cost)
        }else{
          total = total + parseFloat(self.rows[key].turnover.USD.cost)
        }
        
      })
      return total
    },
    exportPDF: function(){

      const doc = new jsPDF('l', 'pt')

      doc.autoTable({ html: '#content', theme: 'grid' })
      // doc.setFontSize(12);
      // doc.text(10, 10, '#', 'center');
      doc.save("rapport_produits.pdf");
    },
    total: function(rate_id){
      var total = 0;
      var self = this
      Object.keys(this.rows).forEach(function(key){
        if(rate_id == 1){
          total = total + parseFloat(self.rows[key].turnover.HTG.total)
        }else{
          total = total + parseFloat(self.rows[key].turnover.USD.total)
        }
        
      })
      return total
    },
    profit: function(rate_id){
      var cost = 0
      var total = 0

      var self = this
      Object.keys(this.rows).forEach(function(key){
        if(rate_id == 1){
          total = total + parseFloat(self.rows[key].turnover.HTG.total)
          cost = cost + parseFloat(self.rows[key].turnover.HTG.cost)
        }else{
          total = total + parseFloat(self.rows[key].turnover.USD.total)
          cost = cost + parseFloat(self.rows[key].turnover.USD.cost)
        }
        
      })

      return total - cost
    },
    margin: function(rate_id){
      var cost = 0
      var total = 0

      var self = this
      Object.keys(this.rows).forEach(function(key){
        if(rate_id == 1){
          total = total + parseFloat(self.rows[key].turnover.HTG.total)
          cost = cost + parseFloat(self.rows[key].turnover.HTG.cost)
        }else{
          total = total + parseFloat(self.rows[key].turnover.USD.total)
          cost = cost + parseFloat(self.rows[key].turnover.USD.cost)
        }

        
        
      })
      var margin = (total - cost)/total*100

      return margin
    },
    totalQuantity: function(){
      var total = 0;
      var self = this
      Object.keys(this.rows).forEach(function(key){
        total = total + parseFloat(self.rows[key].quantity)
      })
      return total
    },
    filter: function(){
      var from = moment(this.from).format('YYYY-MM-DD')
      var to = moment(this.to).format('YYYY-MM-DD')
      var params = 'from='+from+'&to='+to
      if(this.store_id){
        params = params + '&store='+this.store_id
      }
      if(this.category_id){
        params = params + '&category='+this.category_id
      }
      var self = this;
      this.enable = false
        this.$axios.get('/reports/products?'+params)
        .then(function (response) {
          self.rows = response.data.result
          self.enable = true
        }).catch(function(error){
          console.log(error)
        })
    },
    getStores: async function(){
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      await this.$axios.get('/stores?orderasc=name&business_id='+this.business_id)
        .then(function (response) {
          self.stores = response.data
        })
    },
    getCategories: async function(){
      var self = this;
      await this.$axios.get('/categories?orderasc=name')
        .then(function (response) {
          self.categories = response.data
        })
    },
  },
  created(){
    this.filter_rate_id = localStorage.filter_rate_id
    this.load()
    this.getCategories()
    this.getStores()
    
  },
  data: () => ({
      items: [
        {
          text: 'RAPPORT VENTES PAR PRODUIT',
          disabled: false,
          href: '/',
        }
      ],
      rows: [],
      categories: [],
      category_id: '',
      enable: false,
      loading: false,
      fluid: true,
      filter_rate_id: 1,
      store_id:'',
      business_id : 0,
      stores: [],
      from: moment(new Date()).format('YYYY-MM-DD'),
      to: moment(new Date()).format('YYYY-MM-DD')
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  td{
    font-size: 12px!important;
    vertical-align: middle!important;
  }
  td span, th{
    vertical-align: middle!important;
  }
  th{
    font-size: 12px!important;
  }
  td,th{
    text-align: center;
  }
  .v-select__slot{
    border: 1px solid #ced4da;
    padding: 4px 12px;
    border-radius: 4px;
  }
  .v-text-field > .v-input__control > .v-input__slot:before{
    display: none;
  }
  .v-application .v-btn{
    width: 100%!important;
  }
  .v-text-field{
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
  .v-breadcrumbs__item{
    color: black!important;
  }
  .v-text-field__details{
    display: none!important;
  }
  .v-input__slot{
    margin-bottom: 0px!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
    text-align: center; 
    height:41px!important;
    margin-top:0px;
  }

  .v-btn.reset_modal_button{
    background-color:#1867c0 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .v-btn.export_modal_button{
    background: #F44336 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .form-control {
    height: auto!important; 
    padding: 0.45rem 0.75rem!important;
}
</style>